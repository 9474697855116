import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'bg-left-donut': (data) => ({
    background: `url(${data?.leftDonutBackgroundImage?.file.url}?fm=webp&q=100) no-repeat`,
  }),
  // 'bg-dot-matrix': (data) => ({
  //   '&:after': {
  //     backgroundImage: `url(${data?.dotMatrixBackgroundImage?.file?.url}?fm=webp)`,
  //   }}),
    // 'bg-dot-matrix-joinus': (data) => ({
    //   '&:before': {
    //     backgroundImage: `url(${data?.dotMatrixBackgroundImage?.file?.url}?fm=webp)`,
    //   }}),
    'bg-right-capsules': (data) => ({
      background: `url(${data?.rightCapsuleBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
    })
}));
