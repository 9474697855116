import React, {useRef,useEffect}  from "react";
import { Grid } from '@material-ui/core';
import Carousel from 'react-elastic-carousel';
import './WhyCartusOurProudAssociation.scss';
import Item from '../TestimonialItem/Item';
import Container from '@material-ui/core/Container';
import { myStyles } from './styles';
import clsx from 'clsx';

export default function OurProudAssociation({ data,backgroundImg,autoRotateEnabled }) {
  const plusImage = data?.additionImage?.file?.url+'?fm=webp&q=100';
  const items = data?.employeeTestimonials;
  const title = data?.additionImage?.description;
  const classes = myStyles(backgroundImg);
  // breakpoints
  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 599, itemsToShow: 1, itemsToScroll: 1 },
    { width: 904, itemsToShow: 1, itemsToScroll: 1 },
    { width: 1239, itemsToShow: 1, itemsToScroll: 1 },
  ];
  const carouselRef = useRef(null);
  let resetTimeout;
  const carouselProps = {
    breakPoints:breakPoints,
  }
  if(autoRotateEnabled){
    carouselProps.ref=carouselRef
    carouselProps.infiniteLoop = true
    carouselProps.enableAutoPlay= true
    carouselProps.autoPlaySpeed=5000
    carouselProps.isRTL=false
    carouselProps.onNextEnd=({ index }) => {
      clearTimeout(resetTimeout);
      resetTimeout = setTimeout(() => {
        carouselRef?.current?.goTo(0);
      }, 5500);
    }
  }
  const buttonEl = useRef(null);
  const registerAriaLabel = ()=>{
    buttonEl?.current?.querySelectorAll(".rec-dot").forEach((role)=>{
      role.setAttribute('aria-label', 'pagination');  
    });
  }
  useEffect(() => {
    registerAriaLabel();
    if(window) 
    {
      window.addEventListener("resize",()=>{registerAriaLabel()})
    }
  }, []);
  return (
    <Container maxWidth="xl">
    <div ref={buttonEl} className="whycartus-div">
      <div className="our-proud-title">
      <img
        className="plus-image-cartus"
         src={`${plusImage}?fm=webp&q=100`}
        alt={title}
        locId="additionImage"
      />
      <h2 className="title-image-cartus" locId="ourProudAssociationHeading">{data.ourProudAssociationHeading}</h2>
      </div>
      <Grid container>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid
            item
            container
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={clsx("carousel-main-whycartus-cls",classes['carousel-main-whycartus-cls'])}>
            <Carousel {...carouselProps}>
              {items.map((item, i) => (
                <Item key={i} item={item} />
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Grid>
    </div>
    </Container>
  );
}
