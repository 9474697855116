import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'bg-capsules': (capsuleImage) => ({
    background: `url(${capsuleImage?.file?.url}?fm=webp&q=100) no-repeat`,
  }),
  // 'our-winning-momnt-arrow-cls': (data) => ({
  //   '& .rec-arrow-left': (data) => ({
  //      background: `url(${data?.leftArrowImage?.file?.url}) no-repeat !important`
  //   }),
  //   '& .rec-arrow-right': (data) => ({
  //      background: `url(${data?.rightArrowImage?.file?.url}) no-repeat !important`
  //   }),
  //   }),
}));