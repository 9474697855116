import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import WhyCartusLanding from '../components/WhyCartusLanding';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
//import LoginPage from '../pages/login';

export default function WhyCartusPage({ location,pageContext, data }) {
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };


  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulWhyCartus?.seoEntry}
          siteurl={pageContext?.slug}
          bucketName={pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName = {pageContext?.appData?.organizationName}
          organizationLogo = {pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink = {pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink = {pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink = {pageContext?.appData?.organizationYoutubeLink}
          location={location}
          videoSEOSchema ={data?.contentfulWhyCartus?.introSection?.videoComponent?.videoComponent}
        />
        <WhyCartusLanding
          data={data?.contentfulWhyCartus}
          basePath={pageContext.basePath}
          backgroundImg={data?.contentfulComponentBackgroundImages}
        />
      </Layout>
    </>
  );
}
export const query = graphql`
  query whyCartusQuery($contentful_id: String, $locale: String) {
    contentfulComponentBackgroundImages {
      hoverLeftArrowImage {
        file {
          url
        }
      }
      hoverRightArrowImage {
        file {
          url
        }
      }
      leftArrowImage {
        file {
          url
        }
      }
      rightArrowImage {
        file {
          url
        }
      }
    }
    contentfulWhyCartus(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      employeeTestimonialsAutoRotate
      ourWinningMomentsAutoRotate
      proudAssociationLogosAutoRotate
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          file {
            url
          }
        }
      }
      bodyCopy
      dotMatrixBackgroundImage {
        description
        file {
          url
          fileName
        }
      }
      smallDotMatrixBackgroundImage {
        description
        file {
          fileName
          url
        }
      }
      heroImage {
        description
        file {
          url
          fileName
        }
      }
      headerSectionTitle
      headerSectionDescription {
        raw
      }
      headerSectionBackgroundImage {
        description
        file {
          url
          fileName
        }
      }
      introSectionTopic
      introSectionSubTopic
      introSection {
        header
        subHeader
        shortDescription {
          raw
        }
        image {
          description
          file {
            url
          }
        }
        ctaButton {
          link {
            label
            link {
              ... on ContentfulWebLink {
                url
              }
              ... on ContentfulInternalLink {
                slug
              }
            }
          }
        }
        isVideo
        videoComponent {
          videoComponent {
            entryTitle
            videoType
            videoId
            videoTitle
            dateOfPublishing(formatString: "YYYY-MM-DD")
            seoEntry {
              metaDescription {
                metaDescription
              }
            }
          }
        }
      }
      leftDonutBackgroundImage {
        description
        file {
          url
          fileName
        }
      }
      rightCapsuleBackgroundImage{
        description
        file {
          url
          fileName
        }
      }
      additionImage {
        description
        file {
          url
          fileName
        }
      }
      ourProudAssociationHeading
      proudAssosciationTitle
      proudAssosciationSubTitle
      employeeTestimonials {
        authorImage {
          description
          file {
            url
          }
        }
        authorName
        authorDesignation
        authorDescription {
          raw
        }
        secondaryImage {
          description
          file {
            url
          }
        }
      }
      proudAssociationLogos {
        description
        file {
          url
        }
      }
      ourKeyDifferentiatorsHeading
      ourKeyDifferentiatorsMenuTabSection {
        tabTitle
        description {
          raw
        }
        ctaButtonLink {
          label
          link {
            ... on ContentfulInternalLink {
              slug
            }
            ... on ContentfulWebLink {
              url
            }
          }
        }
        pdfImage {
          description
          file {
            url
          }
        }
        uploadPdf {
          description
          file {
            url
          }
        }
        download
      }
      capsulesBackgroundImage {
        description
        file {
          url
        }
      }
      arrowImage {
        description
        file {
          url
        }
      }
      ourWinningMomentsSectionHeading
      ourWinningMomentsSectionSubHeading
      ourWinningMomentsSectionAwards {
        description
        file {
          url
        }
        description
      }
      joinOurTeamSection {
        header
        subHeader
        shortDescription {
          raw
        }
        mainImage {
          description
          title
          file {
            fileName
            url
          }
        }
        additionalImage {
          description
          title
          file {
            fileName
            url
          }
        }
        ctaButton {
          displayPrimaryCta
          labelForPrimaryCta
          callToAction {
            ... on ContentfulInternalLink {
              id
              slug
              pageName
            }
          }
        }
      }
      ourKeyDifferentiatorsBackgroundImage {
        description
        file {
          url
        }
      }
      pardotForm {
        id
        contentful_id
        entryTitle
        header
        subHeader {
          raw
        }
        iFrameLink
        closeIcon {
          description
          file {
            url
          }
        }
      }
    }
  }
`;
