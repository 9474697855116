import React from 'react';
import OurWinningMoments from '../WhyCartusOurProudWinningMoments';
import OurProudAssociation from '../WhyCartusOurProudAssociation';
import OurProudLogo from '../WhyCartusOurProudLogo';
import WhyCartusHeader from '../WhyCartusHeader';
import WhyCartusOurKeyTab from '../WhyCartusOurKeyTab';
import JoinOurTeam from '../JoinOurTeam';
import './WhyCartusLanding.scss';
import { myStyles } from './style';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import RequestFormPopup from '../RequestFormPopup';

const WhyCartusLanding = ({ data, basePath, backgroundImg }) => {
  const classes = myStyles(data);
  return (
    <div className="whycartus-landing">
      <div className={clsx('bg-left-donut', classes['bg-left-donut'])}>
        <WhyCartusHeader data={data} basePath={basePath} />
        <OurProudAssociation
          data={data}
          backgroundImg={backgroundImg}
          autoRotateEnabled={data?.employeeTestimonialsAutoRotate}
        />
        <OurProudLogo
          logo={data.proudAssociationLogos}
          backgroundImg={backgroundImg}
          title={data.proudAssosciationTitle}
          subTitle={data.proudAssosciationSubTitle}
          bodyCopy={data.bodyCopy}
          autoRotateEnabled={data?.proudAssociationLogosAutoRotate}
        />
        <div className={clsx('dot-matrix', classes['bg-dot-matrix'])}>
          <WhyCartusOurKeyTab
            dotMatrix={data?.dotMatrixBackgroundImage}
            heading={data?.ourKeyDifferentiatorsHeading}
            tabData={data?.ourKeyDifferentiatorsMenuTabSection}
            bgImg={data?.ourKeyDifferentiatorsBackgroundImage}
            basePath={basePath}
          />
        </div>
      </div>
      <div className={clsx('capsules-bg', classes['bg-right-capsules'])}>
        <OurWinningMoments
          header={data?.ourWinningMomentsSectionHeading}
          subHeader={data?.ourWinningMomentsSectionSubHeading}
          image={data?.ourWinningMomentsSectionAwards}
          rightArrow={data?.arrowImage?.file?.url + '?fm=webp&q=100'}
          rightArrowAlt={data?.arrowImage?.description}
          capsuleImage={data?.rightCapsuleBackgroundImage}
          backgroundImg={backgroundImg}
          autoRotateEnabled={data?.ourWinningMomentsAutoRotate}
        />
      </div>
      <div className="whycartus-joinus">
        <Container maxWidth="xl" className="no-gutter">
          <JoinOurTeam
            data={data?.joinOurTeamSection}
            matrixImg={data?.smallDotMatrixBackgroundImage}
            basePath={basePath}
          />
        </Container>
        <RequestFormPopup data={data?.pardotForm} images={backgroundImg} />
      </div>
    </div>
  );
};
export default WhyCartusLanding;
