import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'bg-bright-blue': (bgimg) => ({
    backgroundImage: `url(${bgimg?.file?.url}?fm=webp&q=100)`,
  }),
  'bg-dot-matrix': (dotMatrix) => ({
    '&:after': {
      backgroundImage: `url(${dotMatrix?.file?.url}?fm=webp&q=100)`,
    }})
}));