import React, { useRef, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link } from 'gatsby';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { TabContext, TabPanel } from '@mui/lab';
import { Button, Box } from '@material-ui/core';
import './WhyCartusOurKeyTab.scss';
import { myStyles } from './style';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';

const WhyCartusOurKeyTab = ({ dotMatrix, heading, tabData, bgImg, basePath }) => {
  const classes1 = myStyles(bgImg);
  const classes2 = myStyles(dotMatrix);
  const [value, setValue] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const rootEl = useRef(null);
  const removeAriaLabel = () => {
    setTimeout(() => {
      let ourKeyNodes = rootEl?.current?.querySelectorAll('.ourkey-padding');
      ourKeyNodes.forEach((ourKeyNode) => {
        ourKeyNode.removeAttribute('aria-labelledby');
      });
    }, 0);
  };
  useEffect(() => {
    removeAriaLabel();
  }, []);
  return (
    <Container maxWidth="xl" className="zero-padding-div ">
      <div ref={rootEl}>
        <Grid
          container
          spacing={1}
          className={clsx('whycartus-our-key', classes2['bg-dot-matrix'])}
        >
          <Grid item container xl={3} lg={3} md={4} sm={5} xs={7}>
            <h2 className="title" locId="heading">
              {heading}
            </h2>
          </Grid>
          <Grid
            item
            container
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={clsx('txt-with-blue-section', classes1['bg-bright-blue'])}
          >
            <div className="txt-with-blue-section-overlay"></div>
            <Grid
              item
              container
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              spacing={2}
              className="rich-text-div"
            >
              <Box sx={{ width: '100%' }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable prevent tabs example"
                      className="ourkey-tabs"
                      locId="tabTitle"
                    >
                      {tabData.map((tabLabel, index) => (
                        <Tab
                          label={tabLabel.tabTitle}
                          locId={`tabLabel${index}`}
                          value={index + 1}
                          tabIndex="0"
                        />
                      ))}
                    </Tabs>
                  </Box>
                  {tabData.map((tabContent, index) => (
                    <TabPanel value={index + 1} key={index + 1} className="ourkey-padding">
                      <Grid item container>
                        <Grid
                          item
                          xl={7}
                          lg={7}
                          md={7}
                          sm={12}
                          xs={12}
                          spacing={2}
                          className="ourkey-tabcontext"
                          locId="description"
                        >
                          {tabContent?.description?.raw &&
                            documentToReactComponents(JSON.parse(tabContent.description.raw))}
                          {(tabContent?.ctaButtonLink || tabContent?.ctaButtonLink?.label) &&
                            (tabContent?.ctaButtonLink?.link?.url ? (
                              <Link
                                to={
                                  tabContent?.ctaButtonLink?.link?.url
                                    ? tabContent?.ctaButtonLink?.link?.url
                                    : '#'
                                }
                                tabindex="0"
                              >
                                <Button className="button-tab" tabIndex="-1" locId="label">
                                  {tabContent?.ctaButtonLink?.label}
                                </Button>
                              </Link>
                            ) : (
                              <Link
                                to={
                                  tabContent?.ctaButtonLink?.link?.slug
                                    ? `/${basePath}/${tabContent?.ctaButtonLink?.link?.slug}`
                                    : '#'
                                }
                                tabindex="0"
                              >
                                <Button className="button-tab" tabIndex="-1" locId="label">
                                  {tabContent?.ctaButtonLink?.label}
                                </Button>
                              </Link>
                            ))}
                        </Grid>
                        <Grid item xl={5} lg={4} md={4} sm={6} xs={8} spacing={2}>
                          {tabContent?.pdfImage?.file?.url ? (
                            <div className="ourkey-imgtab" locId="pdfImage">
                              <a
                                href={tabContent?.uploadPdf?.file?.url}
                                target="_blank"
                                locId="download"
                                tabIndex="0"
                              >
                                <img
                                  src={`${tabContent?.pdfImage?.file?.url}?fm=webp&q=100`}
                                  alt={tabContent?.pdfImage?.description}
                                />
                                <p className="img-subtitle">{tabContent?.download}</p>
                              </a>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </TabPanel>
                  ))}
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default WhyCartusOurKeyTab;
