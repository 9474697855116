import React from 'react';
import './ourWinningMoments.scss'
import Grid from '@material-ui/core/Grid';
import { myStyles } from './style';
import clsx from 'clsx';
import AwardsSlider from '../AwardsSlider';
import Container from '@material-ui/core/Container';

export default function OurWinningMoments({ header, subHeader, image, rightArrow, capsuleImage, rightArrowAlt, backgroundImg, autoRotateEnabled }) {
  const classes = myStyles(capsuleImage);
  return (
    <>
      <Container maxWidth="xl" className='zero-padding-div'>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className={clsx("our-winning-momnt-cls", classes['our-winning-momnt-arrow-cls'])}>
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <h2 className="heading" locId="ourWinningMomentsSectionHeading">
                <img src={`${rightArrow}?fm=webp&q=100`} alt={rightArrowAlt} className='arrow-img' locId="arrowImage" />
                <span>{header}</span></h2>
              <h3 className="sub-title" locId="ourWinningMomentsSectionSubHeading">{subHeader}</h3>
            </Grid>
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="img-container">
              <AwardsSlider awardsSection={image} backgroundImg={backgroundImg} autoRotateEnabled={autoRotateEnabled}/>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}



