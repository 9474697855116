import React from 'react';
import './WhyCartusProudLogo.scss';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import AwardsSlider from "../AwardsSlider";

export default function OurProudLogo({ logo, backgroundImg,title,subTitle,bodyCopy, autoRotateEnabled }) {
  return (
    <>
      <Container maxWidth="xl" className='zero-padding-div'>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="our-cartus-cls">
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={10} xs={10}>
              <h2 className='title'>{title}</h2>
            </Grid>
          </Grid>
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <h3 className="sub-title">{subTitle}</h3>
              <p>{bodyCopy}</p>
            </Grid>
          </Grid>
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
            <AwardsSlider awardsSection={logo} backgroundImg={backgroundImg} autoRotateEnabled={autoRotateEnabled}/>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
