import React from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { myStyles } from './styles';
import HeaderComponent from '../HeaderComponent/Index';
import HeaderIntroComponent from '../HeaderIntroComponent/Index';
import Container from '@material-ui/core/Container';
const WhyCartusHeader = ({ data, basePath}) => {
  const classes = myStyles(data);
  const {
    headerSectionTitle,
    headerSectionDescription,
    heroImage,
    introSection,
    introSectionTopic,
    introSectionSubTopic,
  } = data;
  return (
    <>
         <div className={clsx('landing-hero-img-div', classes['landing-hero-img-div'])}>
          <HeaderComponent
            heroImage={heroImage}
            classes={classes}
            circularSectionTitle={headerSectionTitle}
            circularSectionDescription={headerSectionDescription}
          />
       </div>
      <Container maxWidth="xl" className='zero-padding-div '>
          <Grid container className={clsx('txt-with-video-container', classes['txt-with-video-container'])}>
          <HeaderIntroComponent
            storyhistorySection={introSection}
            aboutUsStorySectionTopic={introSectionTopic}
            aboutUsStorySectionDescription={introSectionSubTopic}
            classes={classes}
            button={introSection.ctaButton?.link}
            basePath={basePath}
          />
        </Grid>
      </Container>
    </>
  );
};

export default WhyCartusHeader;
